import ApplicationController from "./application_controller";
// import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = ["loadable", "file_upload", "file"]
  connect() {
  }

  toggle(event) {
    // event.preventDefault();
    // Rails.fire(this.element, 'submit');
    if (this.fileTarget.files.length != 0) {
      this.loadableTarget.classList.add('evalii-outline-button-deactivated');
      this.loadableTarget.classList.remove('evalii-outline-button');
    } else {
      this.loadableTarget.classList.add('evalii-outline-button');
      this.loadableTarget.classList.remove('evalii-outline-button-deactivated');
      this.loadableTarget.disabled = false;
      var button = document.getElementById('loading-button');
      button.disabled = false;
      window.location.reload();
    }
    window.location.reload();
  }
}
