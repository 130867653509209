import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

export default class extends ApplicationController {
  static targets = [
    "latest_translation_title",
    "latest_translation_message",
    "latest_translation_id",
    "translation_ticket_id",
    "old_translation",
  ];

  // static targets = ["tab1", "tab2"];
  initialize() {
    this.old_translation = this.get_translation;
  }

  connect() {
    StimulusReflex.register(this);
    this.old_translation = this.get_translation();
    this.add_change_listeners_to_all_targets();
  }

  add_change_listeners_to_all_targets() {
    try {
      this.latest_translation_titleTarget.addEventListener("click", this.change_listener, false) ;
      this.latest_translation_messageTarget.addEventListener("click", this.change_listener, false) ;
    } catch (error) { }

  }

  get_translation(){
    let id = null;
    let ticket_id = null;
    let title = null;
    let message = null;

    if (this.latest_translation_idTarget) {
      id = this.latest_translation_idTarget.value;
    }
    if (this.translation_ticket_idTarget) {
      ticket_id = this.translation_ticket_idTarget.value;
    }
    if (this.latest_translation_titleTarget) {
      title = this.latest_translation_titleTarget.value;
    }
    if (this.latest_translation_messageTarget) {
      message = this.latest_translation_messageTarget.value;
    }

    return {
      ticket_id: ticket_id,
      title: title,
      message: message,
      id: id,
    }
  }

  update_ticket_translation(event) {
    event.preventDefault();
    $('.loader').css('display', 'block');
    $("#save_content_warning").hide();
    const translation = this.get_translation();
    this.stimulate("TranslationsReflex#update", JSON.stringify(translation));
    $("div.success").fadeIn(300).delay(2000).fadeOut(300);
    this.old_translation = translation;
    this.reset_translation();
    this.hide_translation();
  }

  reset_translation(){
    $("#latest_translation_title").html(this.old_translation.title);
    $("#latest_translation_title").val(this.old_translation.title);
    $("#latest_translation_message").html(this.old_translation.message);
    $("#latest_translation_message").val(this.old_translation.message);
    $("#latest_translation_id").val(this.old_translation.message);
  }

  show_translation(event){
    // this.reset_translation();
    $(".translation_show_start").addClass('hidden');
    $(".translation_hidden_start").removeClass('hidden');
  }

  hide_translation(event){
    $(".translation_show_start").removeClass('hidden');
    $(".translation_hidden_start").addClass('hidden');
  }

}
