import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  // static targets = ["canvas"];

  connect() {
  }

  scroll_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

