import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  // static targets = ["output", "text"];
  static targets = ["search_term"];
  connect() {
    StimulusReflex.register(this);
  }

  send_search_term(event) {
    // console.log(event.key);
    if (event.key === "Enter") {
      // in prevent form action
      event.preventDefault();
      // ignorre empty searches
      if (this.search_termTarget.value !== "") {
        var url = "https://www.gelbe-liste.de/suche?term=" + this.search_termTarget.value
        var win = window.open(url, '_blank');
        win.focus();
        // // open collapsed part with animation
        // // the reflex response will overwrite the animation so done
        // $("#search_result").collapse("show");
        // $("#search_result").html(
        //   '<div class="spinner"> <div class= "cube1" > </div > <div class="cube2"></div> </div>'
        // );
        // this.stimulate(
        //   "TicketsReflex#gelbe_liste",
        //   this.search_termTarget.value
        // );
      }
    }
    // console.log(event.key === "Enter");
    // console.log(`Hello, ${this.search_termTarget.value}!`);
  }

  click_search_term(event) {
    event.key = "Enter"
    this.send_search_term(event)
  }



  collapse(event) {
    event.preventDefault();
    $("#search_result").collapse("toggle");
  }
}
