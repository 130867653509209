import ApplicationController from "./application_controller";
import { Controller } from "stimulus";

export default class extends ApplicationController {
  static values = { url: String }

  connect() {
    this.checkStatus(this.urlValue);
  }

  delayedCheck(url) {
    setTimeout(() => {
        this.checkStatus(url);
    }, 1000); // Delay of 1000 milliseconds
  }

  checkStatus(url) {
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        switch(data.status){
          case "new":
            $('#email_send').text("Vorbereitung der Nachricht");
            $('#email_send').attr('class','col ml-3 evalii-ticket-in-progress text-center');
            this.delayedCheck(url);

            break;
          case "in_progress":
            $('#email_send').text("Versende Nachricht");
            $('#email_send').attr('class','col ml-3 evalii-ticket-in-progress text-center');
            this.delayedCheck(url);
            break;
          case "sent":
            $('#email_send').text("Nachricht erfolgreich versendet");
            $('#email_send').attr('class','col ml-3 evalii-ticket-success text-center');
            break;
          case "error":
            $('#email_send').text("Fehler beim Versand der Nachricht");
            $('#email_send').attr('class','col ml-3 evalii-ticket-error text-center');
            break;
        }


        console.log("Status from server:", data.status);
        // You can also update the DOM or state based on the response
      })
      .catch(error => console.error("Error fetching email status:", error));
  }
}
