import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

console.log("run sub NotificationsChannel");

$(document).on("turbolinks:load", function() {
  consumer.subscriptions.create("NotificationsChannel", {
    received(data) {
      if (data.cableReady) CableReady.perform(data.operations);
    },
    connected(data) {
    }
  });
});
